const LuizaWork = (props) => {

  const { luizaData, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  return (
    <div className="view--artist view--luiza">
      <iframe
        className="luiza__iframe"
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px'
        }}
        title={luizaData.title}
        src={luizaData.url}
        frameBorder="none"
      >

      </iframe>
    </div>
  )
}

export default LuizaWork;