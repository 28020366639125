import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { Html, OrbitControls } from '@react-three/drei';
import { getImage } from '../../utils/cockpit.js';

const ArchiveItem = (props) => {
  const { item, activeSpatialArchiveItem, setActiveSpatialArchiveItem, activeArchiveLayer } = props;
  const { title, position, _id, unit_of_time_displayed, image, image_caption, description, layer } = item;
  const group = useRef();
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  const handleClick = () => {
    if (activeSpatialArchiveItem._id !== _id) {
      setActiveSpatialArchiveItem(item);
    } else {
      setActiveSpatialArchiveItem({});
    }
  }

  useFrame(() => {
    const lerpSpeed = 0.03;

    if (group.current) {
      if (activeSpatialArchiveItem._id === _id) {
        let x = (group.current.position.x += (0 - group.current.position.x) * lerpSpeed);
        let y = (group.current.position.y += (0 - group.current.position.y) * lerpSpeed);
        let z = (group.current.position.z += (0 - group.current.position.z) * lerpSpeed);

        group.current.position.set(x, y, z);

      } else {
        if (group.current.position.x !== position[0] || group.current.position.y !== position[1] || group.current.position.z !== position[2]) {

          let x = (group.current.position.x += (position[0] - group.current.position.x) * lerpSpeed);
          let y = (group.current.position.y += (position[1] - group.current.position.y) * lerpSpeed);
          let z = (group.current.position.z += (position[2] - group.current.position.z) * lerpSpeed);

          group.current.position.set(x, y, z);
        }
      }
    }
  });

  return (
    <group
      position={position}
      lookAt={[0, 0, 0]}
      ref={group}
    >
      <Html
        prepend // Project content behind the canvas (default: false)
        center // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]
        distanceFactor={240} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
        transform // If true, applies matrix3d transformations (default=false)
        className="three__html"
      >
        <p
          onClick={handleClick}
          className={`archive__space__item archive__space__text${
            (activeSpatialArchiveItem._id && activeSpatialArchiveItem._id !== _id) || (activeArchiveLayer._id && activeArchiveLayer._id !== layer._id) ? ' transparent' : ''
          }`}
        >{title}</p>
        {
          activeSpatialArchiveItem._id === _id &&
          <div className="archive__space__item--active">
            {
              image &&
              <img
                className={`archive__space__img img--responsive${imageIsLoaded === true ? ' loaded' : ''}`}
                alt={image_caption ? image_caption : `Image for ${title}`}
                src={getImage(image.path, 600, 600)}
                onLoad={() => { setImageIsLoaded(true) }}
              />
            }
            {
              description &&
              <p
                className={`archive__space__item__description archive__space__text`}
              >{description}</p>
            }
            {
              unit_of_time_displayed &&
              <p
                className={`archive__space__item__description archive__space__text`}
              >{unit_of_time_displayed}</p>
            }
            <button
              className={`archive__space__item__close archive__space__text archive__space__button`}
              onClick={() => { setActiveSpatialArchiveItem({}); }}
            >close</button>
          </div>
        }
      </Html>
    </group>
  )
}

const ArchiveSpatialView = (props) => {
  const { archiveData, archiveLayersData, windowDimensions, activeSpatialArchiveItem, setActiveSpatialArchiveItem, camera } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [displayedItems, setDisplayedItems] = useState([]);
  const archiveGroup = useRef();
  const neutralPosition = useRef([
    0, 0, 0
  ]);
  const targetPosition = useRef({
    x: 0,
    y: 0,
    z: 0
  });

  const bigGroup = useRef();
  const controls = useRef();

  useEffect(() => {
    if (archiveData.entries) {
      const allArchiveItems = [];
      const archiveItems = [];

      for (let entry of archiveData.entries) {
        const newEntry = { ...entry }
        allArchiveItems.push(newEntry);
      }

      if (allArchiveItems[0]) {

        let maxNumber = 60;

        if (allArchiveItems.length > maxNumber) {
          for (let i = 0; i < Math.min(maxNumber, allArchiveItems.length); i++) {
            const randomNumber = Math.floor(Math.random() * allArchiveItems.length);
            archiveItems.push({...allArchiveItems[randomNumber]});
            allArchiveItems.splice(randomNumber, 1);
          }
        } else {
          for (let item of allArchiveItems) {
            const newItem = { ...item };
            archiveItems.push(newItem);
          }
        }

        const count = archiveItems.length;
        const radius = Math.max(windowWidth, windowHeight) * .4;
        const position = new THREE.Vector3();
        const archiveItemsArray = [];

        for ( let i = 1; i <= count; i ++ ) {

          const phi = Math.acos( - 1 + ( 2 * i ) / count );
          const theta = Math.sqrt( count * Math.PI ) * phi;

          position.setFromSphericalCoords(radius, phi, theta);


          const newArchiveItem = { ...archiveItems[i - 1] };
          newArchiveItem.position = [position.x, position.y, position.z];
          archiveItemsArray.push(newArchiveItem);
        }

        setDisplayedItems(archiveItemsArray);
        if (archiveGroup.current) {
          archiveGroup.current.position.x = neutralPosition.current[0];
          archiveGroup.current.position.y = neutralPosition.current[1];
          archiveGroup.current.position.z = neutralPosition.current[2];
          if (camera?.current) {
            archiveGroup.current.position.x = camera.current.position.x;
            archiveGroup.current.position.y = camera.current.position.y;
            archiveGroup.current.position.z = camera.current.position.z;
          }
        }
      }
    }
  }, [archiveData.entries, archiveLayersData, windowHeight, windowWidth, camera]);

  useFrame(() => {
    if (archiveGroup.current && camera) {
      if (archiveGroup.current.children[0]) {
        for (let item of archiveGroup.current.children) {
          item.rotation.x = camera.rotation.x;
          item.rotation.y = camera.rotation.y;
          item.rotation.z = camera.rotation.z;
        }
      }
    }
  });

  return (
    <group>
      <group ref={bigGroup}>
        <group ref={archiveGroup} position={neutralPosition}>
          {
            displayedItems[0] &&
            displayedItems.map(
              (item, i) => (
                <ArchiveItem
                  key={i}
                  item={item}
                  {...props}
                  archiveGroup={archiveGroup}
                  activeSpatialArchiveItem={activeSpatialArchiveItem}
                  setActiveSpatialArchiveItem={setActiveSpatialArchiveItem}
                  neutralPosition={neutralPosition.current}
                  targetPosition={targetPosition}
                />
              )
            )
          }
        </group>
      </group>
      <OrbitControls camera={camera} enableZoom={false} ref={controls} position0={neutralPosition.current} enablePan={false} autoRotate={true} autoRotateSpeed={0.5} />
    </group>
  )
}

export default ArchiveSpatialView;