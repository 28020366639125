import { useMemo } from "react";
import * as THREE from "three";
import clearCoatNormalPath from '../../../../img/texture/perlin_noise.jpg';
import normalPath from '../../../../img/texture/Fabric_Rug_008_normal.jpg';

const ArchiveMesh = (props) => {

  const { archiveMesh, setHoveredMenuItemData, setCursorIsOnLink, pathname, position, navigate } = props;

  const normal = useMemo(() => new THREE.TextureLoader().load(normalPath), []);
  const emissive = useMemo(() => new THREE.TextureLoader().load(normalPath), []);
  const clearCoatNormal = useMemo(() => new THREE.TextureLoader().load(clearCoatNormalPath), []);

  return (
    <mesh
      onClick={(event) => {
        if (pathname === '/menu') {
          navigate('/archive');
        } else {
          navigate('/menu');
        }
        setCursorIsOnLink(false);
      }}
      onPointerOver={() => {
        setHoveredMenuItemData({
          artist: 'Archive'
        });
        setCursorIsOnLink(true);
      }}
      onPointerOut={() => {
        setHoveredMenuItemData({});
        setCursorIsOnLink(false);
      }}
      ref={archiveMesh} position={position}>
      <torusKnotBufferGeometry args={[ 20, 64, 64, 30, 50 ]} />
      <meshPhysicalMaterial
        emissive={0xffffff}
        emissiveIntensity={0.1}
        attach="material"
        clearcoat={1.0}
        metalness={0.6}
        roughness={0.1}
        normalScale={new THREE.Vector2(12, 12)}
        clearcoatNormalScale={new THREE.Vector2( 20.0, - 20.0 )}
      >
        <primitive attach="normalMap" object={normal} />
        <primitive attach="emissiveMap" object={emissive} />
        <primitive attach="clearcoatNormalMap" object={clearCoatNormal} />
      </meshPhysicalMaterial>
    </mesh>
  )
}

export default ArchiveMesh;