import { useRef } from "react";
import * as THREE from "three";
import normalPath from '../../../../img/texture/perlin_noise.jpg';
import texturePath from '../../../../img/texture/carbon.jpg';
import emissiveMapPath from '../../../../img/texture/Metal_Grill_021_normal.jpg';

const LuizaMesh = (props) => {

  const { luizaMesh, setHoveredMenuItemData, setCursorIsOnLink, pathname, luizaData, position, navigate } = props;

  const normal = useRef(new THREE.TextureLoader().load(normalPath));
  normal.current.wrapS = THREE.RepeatWrapping;
  normal.current.wrapT = THREE.RepeatWrapping;
  normal.current.repeat = {
    x: 10,
    y: 10
  }
  const texture = useRef(new THREE.TextureLoader().load(texturePath));
  texture.current.wrapS = THREE.RepeatWrapping;
  texture.current.wrapT = THREE.RepeatWrapping;
  texture.current.repeat = {
    x: 10,
    y: 10
  }
  const emissiveMap = useRef(new THREE.TextureLoader().load(emissiveMapPath));
  emissiveMap.current.wrapS = THREE.RepeatWrapping;
  emissiveMap.current.wrapT = THREE.RepeatWrapping;
  emissiveMap.current.repeat = {
    x: 0.1,
    y: 0.1
  }

  return (
    <mesh
      ref={luizaMesh}
      position={position}
      onClick={() => {
        if (pathname === '/menu') {
          navigate(`/${luizaData.title_slug}/intro`);
        } else {
          navigate('/menu');
        }
        setCursorIsOnLink(false);
      }}
      onPointerOver={() => {
        setHoveredMenuItemData({
          artist: luizaData.artist_name,
          title: `${luizaData.title}, ${luizaData.year}`
        });
        setCursorIsOnLink(true);
      }}
      onPointerOut={() => {
        setHoveredMenuItemData({});
        setCursorIsOnLink(false);
      }}
    >
      <torusKnotBufferGeometry args={[ 40, 20, 128, 64 ]} />
      <meshPhysicalMaterial
        attach="material"
        side={THREE.FrontSide}
        clearcoat={1.0}
        clearcoatRoughness={0.105}
        emissive={0x333333}
        emissiveIntensity={0.1}
        roughness={0.5}
        normalMap={normal.current}
        normalScale={[20, 20]}
        map={texture.current}
      >
        <primitive object={emissiveMap.current} attach="emissiveMap" />
      </meshPhysicalMaterial>
    </mesh>
  )
}

export default LuizaMesh;