import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Markdown from 'markdown-to-jsx';

const ActiveReferenceMobile = (props) => {

  const { essayData, activeReference, setActiveReference, windowDimensions } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="essay__active-reference--mobile__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <button
        className="button--close"
        aria-label="Close"
        onClick={() => { setActiveReference('') }}
      />
      <div
        className="essay__active-reference--mobile"
        style={{
          maxHeight: windowHeight - 50 + 'px'
        }}
      >
        {
          activeReference.indexOf('#bibliography') === 0 &&
          essayData.bibliography &&
          essayData.bibliography[parseFloat(activeReference.replace('#bibliography', ''))] &&
          <Markdown>{essayData.bibliography[parseFloat(activeReference.replace('#bibliography', ''))].value}</Markdown>
        }
        <button
          className="essay__active-reference--mobile__close"
          onClick={() => { setActiveReference('') }}
        >Close</button>
      </div>
    </div>
  )
}

const EssayLink = (props) => {

  const { children, href, onClickEssayLink } = props;

  if (href.indexOf('#bibliography') === 0) {
    return (
      <a
        href={href}
        className="essay__link essay__link--bibliography no-underline"
        onClick={(e) => {
          onClickEssayLink(e, href)
        }}
      >
          ({children}){` `}
          <sup>
            [{href.replace('#bibliography', '')}]
          </sup>
      </a>
    )
  } else if (href.indexOf('#footnote') === 0) {
    return (
      <a
        href={href}
        className="essay__link essay__link--footnote no-underline"
      >
        ({children}){` `}
        <sup>
          *
        </sup>
      </a>
    )
  } if (href.indexOf('http') === 0) {
    return (
      <a href={href} target="_blank" rel="noreferrer">{children}</a>
    )
  } else {
    return (
      <Link to={href}>{children}</Link>
    )
  }
}

const Essay = (props) => {

  const { windowDimensions, essayData } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [activeReference, setActiveReference] = useState('');

  const essayColumn = useRef();
  const bibliographyColumn = useRef();

  const onClickEssayLink = (e, href) => {
    e.preventDefault();
    if (bibliographyColumn.current) {
      if (bibliographyColumn.current.querySelector(href)) {
        setActiveReference(href);
        bibliographyColumn.current.scrollTo({
          left: 0,
          top: bibliographyColumn.current.querySelector(href).offsetTop - 120,
          behaviour: 'smooth'
        });
      }
    } else {
      setActiveReference(href);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0}}
      className="view view--info view--layout--default view--padded">
      <div className="container-fluid container-fluid--main view__inner view--layout--default__container-fluid">
        <div className="columns__wrapper view--layout--default__columns__wrapper">
          <div
            className="col-xxs-12 col-xs-9 col-sm-8 col-md-7 scroll-y info__col--main view--layout--default__column essay__view--layout--default__column view--layout--default__column--essay"
            style={{
              height: (windowWidth < 1500 ? windowHeight - 50 : windowHeight - 60) + 'px'
            }}
            ref={essayColumn}
          >
            <div className="view--layout--default__column__inner">
              {
                essayData.essay &&
                <Markdown
                  options={{
                    overrides: {
                      a: {
                        component: EssayLink,
                        props: {
                          onClickEssayLink: onClickEssayLink,
                        },
                      }
                    }
                  }}
                  children={essayData.essay}
                />
              }
            </div>
          </div>
          {
            windowWidth >= 500 &&
            <div className="col-xxs-12 col-xs-3 col-sm-4 col-md-5 view--layout--default__column">
              <div className="view--layout--default__column__inner scroll-y" ref={bibliographyColumn}>
                {
                  essayData.bibliography &&
                  essayData.bibliography.map(
                    (item, i) => (
                      <div className={`essay__reference__item essay__bibliography__item${activeReference === `#bibliography${i + 1}` ? ' active' : ''}`} id={`bibliography${i + 1}`} key={i + 1}>
                        <Markdown children={item.value} />
                      </div>
                    )
                  )

                }
                {
                  essayData.footnotes &&
                  essayData.footnotes.map(
                    (item, i) => (
                      <div className={`essay__reference__item essay__footnotes__item${activeReference === `#footnote${i + 1}` ? ' active' : ''}`} id={`footnote${i}`} key={i}>
                        <Markdown children={item.value} />
                      </div>
                    )
                  )

                }
              </div>
            </div>
          }
          {
            windowWidth < 500 && activeReference !== '' && activeReference &&
            <ActiveReferenceMobile {...props} activeReference={activeReference} setActiveReference={setActiveReference} />
          }
        </div>
      </div>
    </motion.div>
  )
}

export default Essay;