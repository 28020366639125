import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const FPSLimiter = (props) => {
  const { fps } = props;
  const { set } = useThree();

  useEffect(() => {
    const timeUntilNextFrame = (1000 / (fps !== 0 ? fps : 1));
    let raf, timeout;

    const update = () => {
      set({ frameloop: 'always' });
      set({ frameloop: 'never' });
    }

    const setNewFrameTimeout = () => {
      timeout = setTimeout(() => {
        update();
        raf = requestAnimationFrame(setNewFrameTimeout);
      }, [Math.max(0, timeUntilNextFrame)]);
    }

    raf = requestAnimationFrame(setNewFrameTimeout);

    return () => {
      clearTimeout(timeout);
      cancelAnimationFrame(raf);
    };
  }, [fps, set]);

  return null;
}

export default FPSLimiter;