import { useEffect } from 'react';

const MaryWork = (props) => {

  const { maryData, maryActiveWorkIndex, setMaryActiveWorkIndex } = props;

  useEffect(() => {
    setMaryActiveWorkIndex(0);
  }, [setMaryActiveWorkIndex])

  return (
    <nav className="mary-work__menu">
      <ul className="mary-work__menu__list">
      {
        maryData.models &&
        maryData.models.map(
          (item, i) => (
            <li className="mary-work__menu__item"
              key={i}
            >
              <button
                className={`mary-work__menu__item__button${maryActiveWorkIndex === i ? ' active' : ''}`}
                onClick={() => { setMaryActiveWorkIndex(i) }}
              >
                {item.value.name}
              </button>
            </li>
          )
        )
        }
      </ul>
    </nav>
  )
}

export default MaryWork;