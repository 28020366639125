import { useMemo } from "react";
import * as THREE from "three";
import clearCoatNormalPath from '../../../../img/texture/Wood_Wicker_006_normal.jpg';
import normalPath from '../../../../img/texture/Fabric_Rug_008_normal.jpg';
import bumpMapPath from '../../../../img/texture/Metal_Grill_021_normal.jpg';

const EssayMesh = (props) => {

  const { essayMesh, setHoveredMenuItemData, setCursorIsOnLink, pathname, position, navigate } = props;

  const normal = useMemo(() => new THREE.TextureLoader().load(normalPath), []);
  const bumpMap = useMemo(() => new THREE.TextureLoader().load(bumpMapPath), []);
  const clearCoatNormal = useMemo(() => new THREE.TextureLoader().load(clearCoatNormalPath), []);

  return (
    <mesh
      onClick={(event) => {
        if (pathname === '/menu') {
          navigate('/essay');
        } else {
          navigate('/menu');
        }
        setCursorIsOnLink(false);
      }}
      onPointerOver={() => {
        setHoveredMenuItemData({
          artist: 'Essay'
        });
        setCursorIsOnLink(true);
      }}
      onPointerOut={() => {
        setHoveredMenuItemData({});
        setCursorIsOnLink(false);
      }}
      ref={essayMesh} position={position}>
      <cylinderBufferGeometry args={[ 20, 64, 64, 30, 50 ]} />
      <meshPhysicalMaterial
        attach="material"
        clearcoat={1.0}
        metalness={1.0}
        roughness={0.1}
        emissive="hotpink"
        emissiveIntensity={0.2}
        color={0x00ff00}
        normalScale={new THREE.Vector2(2, 2)}
        clearcoatNormalScale={new THREE.Vector2( 2.0, - 2.0 )}
      >
        <primitive attach="emissiveMap" object={bumpMap} />
        <primitive attach="normalMap" object={clearCoatNormal} />
        <primitive attach="clearcoatNormalMap" object={normal} />
      </meshPhysicalMaterial>
    </mesh>
  )
}

export default EssayMesh;