import ArtworkIntroPage from '../components/ArtworkIntroPage.js';

const Luiza = (props) => {

  const { maryData } = props;

  return (
    <ArtworkIntroPage artistData={maryData}  {...props}/>
  )
}

export default Luiza;