import ArtworkIntroPage from '../components/ArtworkIntroPage.js';

const Luiza = (props) => {

  const { luizaData } = props;

  return (
    <ArtworkIntroPage artistData={luizaData} {...props} />
  )
}

export default Luiza;