import { useRef, useMemo } from "react";
import * as THREE from "three";
import envPath from '../../../../img/texture/envMap.jpg';
import { FlakesTexture } from 'three/examples/jsm/textures/FlakesTexture.js';

const flakesTexture = new FlakesTexture();

const SisselMesh = (props) => {

  const { sisselMesh, setHoveredMenuItemData, setCursorIsOnLink, pathname, sisselData, position, navigate } = props;
  const normal = useRef(new THREE.CanvasTexture(flakesTexture));
  normal.current.needsUpdate = true;
  normal.current.needsUpdate = true;
  normal.current.wrapS = THREE.RepeatWrapping;
  normal.current.wrapT = THREE.RepeatWrapping;
  normal.current.repeat = {
    x: 10,
    y: 6
  };
  normal.current.anisotropy = 16;
  const envMap = useMemo(() => new THREE.TextureLoader().load(envPath), []);

  return (
    <mesh
      ref={sisselMesh}
      position={position}
      onClick={() => {
        if (pathname === '/menu') {
          navigate(`/${sisselData.title_slug}/intro`);
        } else {
          navigate('/menu');
        }
        setCursorIsOnLink(false);
      }}
      onPointerOver={() => {
        setHoveredMenuItemData({
          artist: sisselData.artist_name,
          title: `${sisselData.title}, ${sisselData.year}`
        });
        setCursorIsOnLink(true);
      }}
      onPointerOut={() => {
        setHoveredMenuItemData({});
        setCursorIsOnLink(false);
      }}
    >
      <torusBufferGeometry args={[ 20, 48, 120, 40 ]} />
      <meshPhysicalMaterial
        attach="material"
        side={THREE.DoubleSide}
        clearcoat={1.0}
        clearcoatRoughness={0.1}
        roughness={0.5}
        color="#0000ff"
        metalness={0.9}
        normalScale={new THREE.Vector2(20, 20)}
        normalMap={normal.current}
      >
      <primitive
        attach="envMap"
        object={envMap}
        encoding={THREE.sRGBEncoding}
        mapping={THREE.EquirectangularReflectionMapping}
      />
      </meshPhysicalMaterial>
    </mesh>
  )
}

export default SisselMesh;