import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit.js';

const VideoPlayer = (props) => {

  const { item, activeVideoPlayerIndex, setActiveVideoPlayerIndex, index, windowDimensions, setVideosData, videosData } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const { video, x, y, title } = item;
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [startOffset, setStartOffset] = useState({
    x: 0,
    y: 0
  });

  const wrapper = useRef();

  const [videoPos, setVideoPos] = useState({
    x: x,
    y: y
  });

  const [isMouseDown, setIsMouseDown] = useState(false);

  const player = useRef();
  const playerWrapper = useRef();

  useEffect(() => {
    if (typeof player.current !== 'undefined' && typeof playerWrapper.current !== 'undefined') {
      if (player.current.style) {
        player.current.style.height = playerWrapper.current.offsetHeight + 'px';
        player.current.style.width = (playerWrapper.current.offsetHeight / 9) * 16 + 'px';
      }
    }
  }, []);

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  useEffect(() => {
    if (index === activeVideoPlayerIndex && index > 1) {
      player.current.seekTo(0);
    }
  }, [activeVideoPlayerIndex, index]);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleDrag = (e) => {
    if (isMouseDown === true) {
      const mouseX = e.clientX - startOffset.x;
      const mouseY = e.clientY - startOffset.y;

      const xPercent = Math.round((mouseX / windowWidth) * 100);
      const yPercent = Math.round((mouseY / windowHeight) * 100);

      setVideoPos({
        x: xPercent,
        y: yPercent
      });
    }
  }

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartOffset({
      x: e.clientX - wrapper.current.offsetLeft,
      y: e.clientY - wrapper.current.offsetTop
    })
  }

  const updateVideoData = () => {

    const newItem = { ...item }
    newItem.x = videoPos.x;
    newItem.y = videoPos.y;

    const apiKey = process.env.REACT_APP_API_KEY;

    fetch(`${cockpitUrl}/api/collections/save/sissel_work?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: newItem
      })
    })
    .then(res => res.json()).then((res) => {
    })
    .catch(error => {
      console.log(error);
    });
  }

  const handleMouseUp = (e) => {
    setIsMouseDown(false);
    const newVideosData = { ...videosData };
    newVideosData[index].x = videoPos.x;
    newVideosData[index].y = videoPos.y;
    setVideosData(newVideosData);

    updateVideoData();
  }

  return (
    <div
      className={`sissel__video${index === activeVideoPlayerIndex ? ' active' : ''} editor`}
      style={{
        left: videoPos.x + '%',
        top: videoPos.y + '%',
        zIndex: isMouseDown === true ? 100 : null
      }}
      onMouseMove={handleDrag}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onBlur={handleMouseUp}
      onClick={() => {
        setActiveVideoPlayerIndex(index);
      }}
      ref={wrapper}
    >
      <div className={`video-player`} ref={playerWrapper}>
        <ReactPlayer
          ref={player}
          url={video}
          style={{
            pointerEvents: 'none'
          }}
          height={'0%'}
          width={'100%'}
          playsinline={true}
          playing={false}
          volume={index === activeVideoPlayerIndex ? 1 : 0}
          muted={index === activeVideoPlayerIndex ? false : true}
          loop={index === activeVideoPlayerIndex ? false : true}
          onEnded={() => { setActiveVideoPlayerIndex(index + 1) }}
          className="video-player__inner aspect-ratio--16-9"
          onProgress={(e) => {
            getCurrentTime(e)
          }}
          onSeek={(e) => {
            getCurrentTime(e)
          }}
          onDuration={(e) => {
            getDuration(e)
          }}
        />
        <div className="sissel--edit__video__meta">
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--number">{index + 1}</p>
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--x">{videoPos.x}%</p>
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--y">{videoPos.y}%</p>
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--title">{title}</p>
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--current-time">{currentTime}s</p>
          <p className="sissel--edit__video__meta__item sissel--edit__video__meta__item--duration">{duration}s</p>
        </div>
      </div>
    </div>
  )
}

const SisselWork = (props) => {

  const { windowDimensions, sisselWorkData } = props;
  const { entries } = sisselWorkData;
  const { windowWidth, windowHeight } = windowDimensions;
  const [activeVideoPlayerIndex, setActiveVideoPlayerIndex] = useState(0);
  const [videosData, setVideosData] = useState([]);
  const container = useRef();

  useEffect(() => {
    if (entries) {
      setVideosData([...entries]);
    }
  }, [entries]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: .2 }}
      className="view--artist view--sissel view--sissel--edit"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <motion.div
        className="view--sissel__inner view--sissel--edit__inner"
        ref={container}
      >
        {
          entries &&
          entries.map(
            (item, i) => (
              <VideoPlayer
                item={item}
                key={i}
                index={i}
                activeVideoPlayerIndex={activeVideoPlayerIndex}
                setActiveVideoPlayerIndex={setActiveVideoPlayerIndex}
                videosData={videosData}
                setVideosData={setVideosData}
                {...props}
              />
            )
          )
        }
      </motion.div>
      <p
        style={{
          pointerEvents: 'all'
        }}
        className="sissel__play"
      >Drag the videos around the pink square to update their positions on the live site.</p>
    </motion.div>
  )
}

export default SisselWork;