import { Link } from 'react-router-dom';

const MainMenu = (props) => {

  return (
    <Link to="/info" className={`link--info button--default`}
      style={{
        zIndex: 9999
      }}
    >Info</Link>
  )
}

export default MainMenu;