import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { getImage } from '../utils/cockpit.js';
import { Fragment } from 'react';

const ArchiveListItem = (props) => {

  const { item, handleClickItemButton, activeItemIndex, index, further_reading } = props;
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  const onImageLoad = () => {
    setImageIsLoaded(true);
  }

  return (
    <li className="archive__list__item">
      {/* {JSON.stringify(item)} */}
      <div className="archive__list__item__row archive__list__item__row--main columns__wrapper">
        <div className="archive__list__item__column d-xxs-none d-xs-block col-xs-1 offset-xs-1 order-xs-0">{item.archiveIndex}</div>
        <div className="archive__list__item__column archive__list__item__column--time col-xxs-2 col-xs-2 order-xxs-4 order-xs-1">
          {item.unit_of_time_displayed}
        </div>
        <div className="archive__list__item__column col-xxs-10 col-xs-3 order-xxs-0 order-xs-2">
          {item.title}
        </div>
        <div className="archive__list__item__column col-xxs-10 col-xs-2 order-xxs-3 order-xs-3">
          {item.layer.display}
        </div>
        <div className="archive__list__item__column col-xxs-12 col-xs-2 order-xxs-2 order-xs-4">
          {item.author}
        </div>
        <div className="archive__list__item__column archive__list__item__column--button col-xxs-2 col-xs-1 order-xxs-1 order-xs-5">
          {
            (item.image || item.description || item.credit) &&
            <button
              className={`archive__list__item__button${activeItemIndex === index ? ' active' : ''}`}
              onClick={() => {
                handleClickItemButton(index);
              }}
              aria-label={activeItemIndex === index ? ' close info' : ' show info'}
            ></button>
          }
        </div>
      </div>
      {
        activeItemIndex === index &&
        <div className="archive__list__item__row archive__list__item__row--secondary columns__wrapper">
            <div className="col-xxs-12 col-xs-6 offset-xs-1">
              {
                item.description &&
                  <p>{item.description}</p>
              }
            </div>
            <div className="col-xxs-12 col-xs-5">
            {
              item.image &&
              <Fragment>
                <img
                    className={`img img--responsive${imageIsLoaded === true ? ' loaded' : ''}`}
                    src={getImage(item.image.path, 2048, 2048)}
                    alt={item.image.title ? item.image.title : `Image for ${item.title}`}
                    onLoad={onImageLoad}
                  />
                <p>{item.image_credit}</p>
              </Fragment>
            }
          </div>
          <div className="col-xxs-12 col-xs-6 offset-xs-1">
            {item.credit}
          </div>
          <div className="col-xxs-12 col-xs-5">
            {further_reading}
          </div>
          <div className="col-xxs-12 col-xs-6 offset-xs-1">
            <p>See also:</p>
            {
              item.cross_referencing &&
              item.cross_referencing.map(
                (item, i) => (
                  <p className="cross-reference" key={i}>{item.display}</p>
                )
              )
            }
          </div>
        </div>
      }
      <div
        className="archive__list__item__row archive__list__item__row--border-bottom columns__wrapper"
      >
        <div className="archive__list__item__col__border-bottom col-xxs-12 col-xs-11 offset-xs-1 no-padding">
          <div className="archive__list__item__border-bottom"></div>
        </div>
      </div>
    </li>
  )
}

const ArchiveSpaceLayersNav = (props) => {

  const { archiveLayersData, activeArchiveLayer, setActiveArchiveLayer } = props;

  const handleChange = (e, item) => {
    if (e.target.checked === true) {
      setActiveArchiveLayer(item);
    }
  }

  return (
    <form className="archive__space__form--layers">
      {
        archiveLayersData.entries &&
        archiveLayersData.entries.map(
          (item, i) => (
            <label
              className={`archive__space__form--layers__label${activeArchiveLayer._id === item._id ? ' active' : ''}`}
              key={i}
            >
              <span>{item.title}</span>
              <input
                type="radio"
                onChange={(e) => { handleChange(e, item); }}
                value={item.title}
                name="Archive Layer"
                checked={activeArchiveLayer._id === item._id ? true : false}
              />
            </label>
          )
        )
      }
    </form>
  )
}

const ArchiveList = (props) => {

  const [isScrolled, setIsScrolled] = useState(false);
  const { windowDimensions, archiveData } = props;
  const { windowHeight } = windowDimensions;
  const [activeItemIndex, setActiveItemIndex] = useState(-1);
  const [sortBy, setSortBy] = useState({});
  const [archiveListSorted, setArchiveListSorted] = useState([]);

  const handleClickItemButton = (index) => {
    if (index !== activeItemIndex) {
      setActiveItemIndex(index);
    } else {
      setActiveItemIndex(-1);
    }
  }

  useEffect(() => {

    const sortArrayOfObjects = (propertyPath, array, direction) => {
      let path = propertyPath.split('.');

      const getValue = (obj, path) => {
        path.forEach(path => obj = obj[path])
        return obj;
      }

      if (direction === -1) {
        return array.sort((b, a) => {
          return getValue(b, path) < getValue(a, path) ? 1 : -1
        });
      } else {
        return array.sort((a, b) => {
          return getValue(b, path) > getValue(a, path) ? -1 : 1
        });
      }
    }

    const sortArchiveItems = () => {
      if (archiveData.entries) {
        const archiveArray = [];
        let i = 0;
        for (let entry of archiveData.entries) {
          const newEntry = { ...entry };
          newEntry.archiveIndex = i;
          archiveArray.push(newEntry);
          i++;
        }

        if (sortBy.column && sortBy.direction) {
          const sortedArray = sortArrayOfObjects(sortBy.column, archiveArray, sortBy.direction);
          setArchiveListSorted(sortedArray);
        } else {
          setArchiveListSorted(archiveArray);
        }
      }
    }

    sortArchiveItems();

  }, [archiveData.entries, sortBy.column, sortBy.direction]);

  const handleScroll = (e) => {
    const scrollAmount = e.target.scrollTop;
    const paddingTop = parseFloat(window.getComputedStyle(e.target).paddingTop);
    if (scrollAmount >= paddingTop) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  const handleClickHeaderButton = (column) => {
    setSortBy({
      column: column,
      direction: sortBy.column !== column ? 1 : sortBy.direction === -1 ? 1 : -1
    });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
      onScroll={handleScroll}
      className={`archive__list__wrapper scroll-y${isScrolled === true ? ' scrolled' : ''}`}
    >
      <div className={`archive__list__header container-fluid${isScrolled === true ? ' scrolled' : ''}`}>
        <div className="columns__wrapper archive__list__header__row">
          <div className="col-xs-1 d-xxs-none d-xs-block archive__list__header__column"></div>
          <div className="col-xs-1 d-xxs-none d-xs-block archive__list__header__column"></div>
          <div className="col-xxs-3 col-xs-2 archive__list__header__column">
            <span className="archive__list__heading">Time</span>
            <button
              className={`archive__list__heading__button${sortBy.column === 'unit_of_time_for_ordering' ? sortBy.direction === 1 ? ' descending' : ' ascending' : ''}`}
              onClick={() => {
                handleClickHeaderButton('unit_of_time_for_ordering')
              }}
            />
          </div>
          <div className="col-xxs-3 archive__list__header__column">
            <span className="archive__list__heading">Title</span>
            <button
              className={`archive__list__heading__button${sortBy.column === 'title' ? sortBy.direction === 1 ? ' descending' : ' ascending' : ''}`}
              onClick={() => {
                handleClickHeaderButton('title')
              }}
            />
          </div>
          <div className="col-xxs-3 col-xs-2 archive__list__header__column">
            <span className="archive__list__heading">Layer</span>
            <button
              className={`archive__list__heading__button${sortBy.column === 'layer.display' ? sortBy.direction === 1 ? ' descending' : ' ascending' : ''}`}
              onClick={() => {
                handleClickHeaderButton('layer.display')
              }}
            />
          </div>
          <div className="col-xxs-3 col-xs-3 col-sm-md-2 archive__list__header__column">
            <span className="archive__list__heading">Author</span>
            <button
              className={`archive__list__heading__button${sortBy.column === 'author' ? sortBy.direction === 1 ? ' descending' : ' ascending' : ''}`}
              onClick={() => {
                handleClickHeaderButton('author')
              }}
            />
          </div>
          <div className="d-xxs-none d-sm-md-block col-sm-md-1 archive__list__header__column">

          </div>
        </div>
      </div>
      <ul
        className={`view__inner container-fluid archive__list${isScrolled === true ? ' scrolled' : ''}`}
      >
        {
          archiveListSorted.map(
            (item, i) => (
              <ArchiveListItem
                item={item}
                {...props}
                key={i}
                index={i}
                handleClickItemButton={handleClickItemButton}
                activeItemIndex={activeItemIndex}
                setActiveItemIndex={setActiveItemIndex}
              />
            )
          )
        }
      </ul>
    </motion.div>
  )
}

const Archive = (props) => {

  const { archiveView, setArchiveView, activeSpatialArchiveItem, windowDimensions } = props;
  const { windowWidth } = windowDimensions;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`view view--archive ${ archiveView === 'space' && !activeSpatialArchiveItem._ids && windowWidth > 767 ? ' no-touch' : '' }`}
    >
      {
        windowWidth > 767 &&
        <form className="archive-view__controls">
          <label className={`archive-view__item${archiveView === 'space' ? ' active' : ''}`}>
            Space
            <input type="radio" name="archive view" value="space" checked={archiveView === 'space'}
              onChange={
                (e) => {
                  if (e.target.checked === true) {
                    setArchiveView('space');
                  } else {
                    setArchiveView('list');
                  }
                }
              } />
          </label>
          <label className={`archive-view__item${archiveView === 'list' ? ' active' : ''}`}>
            List
            <input type="radio" name="archive view" value="list" checked={archiveView === 'list'}
              onChange={
                (e) => {
                  if (e.target.checked === true) {
                    setArchiveView('list');
                  } else {
                    setArchiveView('space');
                  }
                }
              } />
          </label>
        </form>
      }
      <AnimatePresence>
        {
          (archiveView === 'list' || windowWidth < 768) &&
          <ArchiveList {...props} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          archiveView === 'space' && windowWidth > 767 &&
          <ArchiveSpaceLayersNav {...props} />
        }
      </AnimatePresence>
    </motion.div>
  )

}

export default Archive;