import { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import ThreeMainMenu from './ThreeMainMenu.js';
import * as THREE from 'three';
import Particles from './Particles.js';
import ArchiveSpatialView from './ArchiveSpatialView.js';
import MaryArtwork from './Mary/MaryArtwork.js';
import FPSLimiter from '../../utils/FPSLimiter.js';

const Camera = (props) => {
  const { camera, isMobile } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      fov={27}
      position={[0, 320, 1200]}
      rotation={[-0.3, 0, 0]}
      near={1} far={10000}
    >
      <Particles {...props} isMobile={isMobile} camera={camera.current} />
      <ThreeMainMenu {...props} />
    </PerspectiveCamera>
  )
}

const ThreeScene = (props) => {

  const { pathname, archiveView, windowDimensions } = props;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { windowWidth, windowHeight } = windowDimensions;

  const camera = useRef();

  return (
    <div
      className="three__wrapper"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <Canvas
        pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
        onCreated={state => {
          state.gl.toneMapping = THREE.CineonToneMapping;
          state.gl.toneMappingExposure = 1.65;
          // state.gl.outputEncoding = THREE.sRGBEncoding;
          state.gl.alpha = true;
          state.gl.setClearColor("white", 0);
        }}
      >
        <FPSLimiter fps={48} />
        <Camera {...props} camera={camera} isMobile={isMobile} />

          {
            archiveView === 'space' && pathname === '/archive' && windowWidth > 767 &&
            <ArchiveSpatialView {...props} camera={camera.current} />
          }
        {
          pathname === `/${props.maryData.title_slug}/view` &&
          <MaryArtwork {...props} />
        }
      </Canvas>
    </div>
  )
}

export default ThreeScene;