import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Fragment } from 'react';

const Info = (props) => {

  const { windowDimensions, infoData } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  return (
    <motion.div className="view view--info view--layout--default view--padded">
      <div className="container-fluid container-fluid--main view__inner view--layout--default__container-fluid">
        <div className="columns__wrapper view--layout--default__columns__wrapper">
          <div className="col-xxs-12 col-xs-9 col-sm-8 col-md-7 scroll-y info__col--main view--layout--default__column" height={windowHeight}>
            <div className="view--layout--default__column__inner">
              <ReactMarkdown>{infoData.intro}</ReactMarkdown>
              {
                windowWidth < 500 &&
                <ReactMarkdown>{infoData.credits}</ReactMarkdown>
              }
            </div>
          </div>
          {
            windowWidth > 499 &&
            <div className="col-xxs-12 col-xs-3 col-sm-4 col-md-5 scroll-y view--layout--default__column">
              <div className="view--layout--default__column__inner">
                <ReactMarkdown>{infoData.credits}</ReactMarkdown>
                <Link to="/menu" className="link--enter">Enter</Link>
              </div>
            </div>
          }
        </div>
      </div>
      {
        windowWidth < 500 &&
        <Fragment>
          <Link to="/menu" className="link--enter--mobile button--default">Enter</Link>
        </Fragment>
      }
    </motion.div>
  )
}

export default Info;