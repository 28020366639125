import { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ThreeScene from './components/Three/ThreeScene.js';
import { cockpitUrl } from './utils/cockpit.js';
import Header from './components/Header.js';
import MainMenu from './views/MainMenu.js';
import Archive from './views/Archive.js';
import Info from './views/Info.js';
import Essay from './views/Essay.js';
import Luiza from './views/Luiza.js';
import LuizaWork from './views/LuizaWork.js';
import Sissel from './views/Sissel.js';
import SisselWork from './views/SisselWork.js';
import SisselEditor from './views/SisselEditor.js';
import Mary from './views/Mary.js';
import MaryWork from './views/MaryWork.js';
import Cursor from './components/Cursor.js';
import { AnimatePresence } from 'framer-motion';
import * as _ from 'underscore';
import LandingPage from './views/LandingPage.js';

function App() {

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [landingData, setLandingData] = useState({});
  const [infoData, setInfoData] = useState({});
  const [essayData, setEssayData] = useState({});
  const [deviceMotionIsSupported, setDeviceMotionIsSupported] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [deviceMotionPosition, setDeviceMotionPosition] = useState({ x: 0, y: 0, z: 0 });
  const [hoveredMenuItemData, setHoveredMenuItemData] = useState({});
  const [cursorIsOnLink, setCursorIsOnLink] = useState(false);
  const [sisselData, setSisselData] = useState({});
  const [sisselWorkData, setSisselWorkData] = useState({});
  const [maryData, setMaryData] = useState({});
  const [luizaData, setLuizaData] = useState({});
  const [archiveData, setArchiveData] = useState({});
  const [archiveLayersData, setArchiveLayersData] = useState({});
  const [archiveView, setArchiveView] = useState('space');
  const [isTouchScreen, setIsTouchScreen] = useState(null);
  const [activeSpatialArchiveItem, setActiveSpatialArchiveItem] = useState({});
  const [maryActiveWorkIndex, setMaryActiveWorkIndex] = useState(0);

  const [activeArchiveLayer, setActiveArchiveLayer] = useState({});

  const handleMouseMove = (e) => {
      let x, y;
      if (e.clientX && e.clientY) {
        x = e.clientX;
        y = e.clientY;
      }
      if (e.touches) {
        if (isTouchScreen !== true) {
          setIsTouchScreen(true);
        }
        if (e.touches[0]) {
          x = e.touches[0].clientX;
          y = e.touches[0].clientY;
        }
      } else {
        if (isTouchScreen === null) {
          setIsTouchScreen(false);
        }
      }
      if (x && y) {
        setMousePosition({
          x: x,
          y: y
        });
      }
  }

  const handleMouseMoveThrottled = _.throttle(handleMouseMove, 60);

  useEffect(() => {

    const handleResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }

    const handleDeviceMotionEvent = (e) => {
      setDeviceMotionPosition({
        x: e.beta,
        y: e.gamma,
        z: e.alpha
      });
    }

    const apiKey = process.env.REACT_APP_API_KEY;

    const fetchLandingData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/landing?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setLandingData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchInfoData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/info?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setInfoData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchEssayData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/essay?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setEssayData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchSisselData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/sissel?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setSisselData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchSisselWorkData = () => {
      fetch(`${cockpitUrl}/api/collections/get/sissel_work?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setSisselWorkData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchMaryData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/mary?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setMaryData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchLuizaData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/luiza?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setLuizaData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchArchiveLayersData = () => {
      fetch(`${cockpitUrl}/api/collections/get/archive_layers?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setArchiveLayersData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    const fetchArchiveData = () => {
      fetch(`${cockpitUrl}/api/collections/get/archive?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setArchiveData(response);
      })
      .catch(error => {
        console.log(error);
      });
    }

    fetchInfoData();
    fetchLandingData();
    fetchEssayData();
    fetchSisselData();
    fetchSisselWorkData();
    fetchMaryData();
    fetchLuizaData();
    fetchArchiveData();
    fetchArchiveLayersData();
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    let resizeTimeout;
    const setWindowResizeTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        handleResize, 200
      )
    }

    const requestDeviceMotionPermission = () => {
      DeviceMotionEvent.requestPermission()
      .then(response => {
        if (response === 'granted') {
          setDeviceMotionIsSupported(true);
          window.deviceMotionIsSupported = true;
          window.removeEventListener('click', requestDeviceMotionPermission);
          window.removeEventListener('touchstart', requestDeviceMotionPermission);
        }
      })
      .catch(console.error)
    }

    if (deviceMotionIsSupported === false) {
      if (typeof(DeviceMotionEvent) !== 'undefined') {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          // iOS 13+
          window.addEventListener('click', requestDeviceMotionPermission);
          window.addEventListener('touchstart', requestDeviceMotionPermission);
        }
      }
    }

    window.addEventListener('deviceorientation', handleDeviceMotionEvent);
    window.addEventListener('resize', setWindowResizeTimeout);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('deviceorientation', handleDeviceMotionEvent);
      window.removeEventListener('click', requestDeviceMotionPermission);
      window.removeEventListener('touchstart', requestDeviceMotionPermission);
      window.removeEventListener('resize', setWindowResizeTimeout);
    }
  }, [deviceMotionIsSupported, isTouchScreen]);

  return (
  <div id="main">
    <div
      className="App"
      onMouseMove={(e) => { if (location.pathname !== '/essay' && location.pathname !== '/archive') { handleMouseMoveThrottled(e); }}}
      onTouchMove={(e) => { if (location.pathname !== '/essay' && location.pathname !== '/archive') { handleMouseMoveThrottled(e); }}}
      style={{
        width: windowDimensions.windowWidth + 'px',
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      <ThreeScene
        windowDimensions={windowDimensions}
        pathname={location.pathname}
        setHoveredMenuItemData={setHoveredMenuItemData}
        setCursorIsOnLink={setCursorIsOnLink}
        mousePosition={mousePosition}
        deviceMotionIsSupported={deviceMotionIsSupported}
        deviceMotionPosition={deviceMotionPosition}
        archiveData={archiveData}
        archiveLayersData={archiveLayersData}
        archiveView={archiveView}
        maryData={maryData}
        luizaData={luizaData}
        sisselData={sisselData}
        activeSpatialArchiveItem={activeSpatialArchiveItem}
        setActiveSpatialArchiveItem={setActiveSpatialArchiveItem}
        activeArchiveLayer={activeArchiveLayer}
        maryActiveWorkIndex={maryActiveWorkIndex}
        location={location} navigate={navigate}
      />
      <AnimatePresence>
        <Routes key={location.pathname}>
          <Route exact path="/" element={
            <LandingPage landingData={landingData} windowDimensions={windowDimensions} pathname={location.pathname} location={location} navigate={navigate} />
          } />
          <Route exact path="/menu" element={
            <MainMenu hoveredMenuItemData={hoveredMenuItemData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path="/archive" element={
            <Archive
              archiveData={archiveData}
              archiveView={archiveView}
              setArchiveView={setArchiveView}
              activeSpatialArchiveItem={activeSpatialArchiveItem}
              setActiveSpatialArchiveItem={setActiveSpatialArchiveItem}
              windowDimensions={windowDimensions}
              archiveLayersData={archiveLayersData}
              activeArchiveLayer={activeArchiveLayer}
              setActiveArchiveLayer={setActiveArchiveLayer}
              location={location} navigate={navigate}
            />
          } />
          <Route exact path="/info"
            element={
              <Info infoData={infoData} windowDimensions={windowDimensions} pathname={location.pathname} location={location} navigate={navigate} />
          } />
          <Route exact path="/essay"
            element={
              <Essay essayData={essayData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path={`/${sisselData.title_slug}/intro`}
            element={
              <Sissel sisselData={sisselData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path={`/${sisselData.title_slug}/view`}
            element={
              <SisselWork sisselData={sisselData} windowDimensions={windowDimensions} location={location} navigate={navigate} mousePosition={mousePosition} sisselWorkData={sisselWorkData} />
          } />
          <Route exact path={`/${sisselData.title_slug}/edit`}
            element={
              <SisselEditor sisselData={sisselData} windowDimensions={windowDimensions} location={location} navigate={navigate} mousePosition={mousePosition} sisselWorkData={sisselWorkData} setSisselWorkData={setSisselWorkData} />
          } />
          <Route exact path={`/${luizaData.title_slug}/intro`}
            element={
              <Luiza luizaData={luizaData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path={`/${luizaData.title_slug}/view`}
            element={
              <LuizaWork luizaData={luizaData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path={`/${maryData.title_slug}/intro`}
            element={
              <Mary maryData={maryData} windowDimensions={windowDimensions} location={location} navigate={navigate} />
          } />
          <Route exact path={`/${maryData.title_slug}/view`}
            element={
              <MaryWork maryData={maryData} windowDimensions={windowDimensions} location={location} navigate={navigate} maryActiveWorkIndex={maryActiveWorkIndex} setMaryActiveWorkIndex={setMaryActiveWorkIndex} />
          } />
        </Routes>
      </AnimatePresence>
      <AnimatePresence>
        <Header
          hoveredMenuItemData={hoveredMenuItemData}
          pathname={location.pathname}
          archiveData={archiveData}
          maryData={maryData}
          luizaData={luizaData}
          sisselData={sisselData}
          location={location} navigate={navigate}
        />
      </AnimatePresence>
    </div>
      {
        isTouchScreen === false && (location.pathname === '/' || location.pathname === '/menu') &&
        <Cursor cursorIsOnLink={cursorIsOnLink} />
      }
    </div>
  );
}

export default App;
