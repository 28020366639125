import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { toRoman } from 'roman-numerals';

const VideoPlayer = (props) => {

  const { item, activeVideoPlayerIndex, setActiveVideoPlayerIndex, index, container } = props;
  const { video, x, y, always_looping } = item;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [floatNumber] = useState(Math.ceil(Math.random() * 3));
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [ccIsActive, setCcIsActive] = useState(false);
  const [playerKey, setPlayerKey] = useState(0);

  const player = useRef();
  const playerWrapper = useRef();

  useEffect(() => {
    if (typeof player.current !== 'undefined' && typeof playerWrapper.current !== 'undefined') {
      if (player.current.style) {
        player.current.style.height = playerWrapper.current.offsetHeight + 'px';
        player.current.style.width = (playerWrapper.current.offsetHeight / 9) * 16 + 'px';
      }
    }
  }, []);

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  useEffect(() => {
    if (activeVideoPlayerIndex === index) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [activeVideoPlayerIndex, index]);

  useEffect(() => {
    if (index === activeVideoPlayerIndex && index > 1) {
      player.current.seekTo(0);
    }
  }, [activeVideoPlayerIndex, index]);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value)
  }

  return (
    <motion.div
      drag={isScrubbing === false ? true : false}
      dragConstraints={container}
      className={`sissel__video${index === activeVideoPlayerIndex ? ' active' : ''}`}
      style={{
        left: x + '%',
        top: y + '%',
        zIndex: mouseIsOver === true ? 1000 : activeVideoPlayerIndex === index ? 999 : null
      }}
      onMouseUp={() => {
        if (always_looping !== true) {
          setActiveVideoPlayerIndex(index);
        }
      }}
      onClick={() => {
        if (always_looping !== true) {
          setActiveVideoPlayerIndex(index);
        } else {
          setIsPlaying(true);
        }
      }}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onTouchStart={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}
      onTouchEnd={() => {
        setMouseIsOver(false);
      }}
      onBlur={() => {
        setMouseIsOver(false);
      }}
    >
      <div className={`video-player float--${floatNumber}`} ref={playerWrapper}>
        <ReactPlayer
          ref={player}
          key={playerKey}
          url={video}
          style={{
            pointerEvents: 'none'
          }}
          height={'0%'}
          width={'100%'}
          playsinline={true}
          playing={always_looping === true ? true : isPlaying}
          volume={index === activeVideoPlayerIndex ? 1 : 0}
          muted={always_looping === true ? true : index === activeVideoPlayerIndex ? false : true}
          loop={always_looping !== true ? false : true}
          onEnded={() => { setActiveVideoPlayerIndex(index + 1) }}
          className="video-player__inner aspect-ratio--16-9"
          onPlay={() => { setIsPlaying(true); }}
          onPause={() => { setIsPlaying(false) }}
          config={{
            vimeo: {
              playerOptions: {
                texttrack: ccIsActive === true ? 'en' : null
              }
            }
          }}
          onProgress={(e) => {
            getCurrentTime(e)
          }}
          onSeek={(e) => {
            getCurrentTime(e)
          }}
          onDuration={(e) => {
            getDuration(e)
          }}
        />
        {
          (mouseIsOver === true || (isPlaying === false && index === activeVideoPlayerIndex)) && always_looping !== true &&
          <div className="video-player__controls">
            <div className="video-player__controls__inner">
              <div className="video-player__time__wrapper">
                <button
                  className={`video-player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                  onClick={() => { setIsPlaying(!isPlaying) }}
                />
                <div className="video-player__input--time__wrapper">
                  <input
                    type="range"
                    className="video-player__input--time"
                    name="time"
                    max={duration}
                    value={currentTime}
                    onChange={(e) => {
                      setIsPlaying(false);
                      setCurrentTime(e.target.value);
                      handleSeek(e);
                    }}
                    onMouseDown={() => {
                      setIsPlaying(false);
                      setIsScrubbing(true);
                    }}
                    onMouseUp={() => {
                      setIsPlaying(true);
                      setIsScrubbing(false);
                    }}
                    onBlur={() => {
                      setIsScrubbing(false);
                    }}
                  />
                </div>
              </div>
              <button
                className="cc-button"
                aria-label={ccIsActive === true ? 'Deactivate closed captions' : 'Activate closed captions'}
                onClick={() => {
                  setCcIsActive(!ccIsActive);
                  setPlayerKey(playerKey + 1);
                }}
              >CC</button>
            </div>
          </div>
        }
        {
          index !== activeVideoPlayerIndex && always_looping !== true &&
          <span className="sissel__video__number">
            {toRoman(index + 1)}
          </span>
        }
      </div>
    </motion.div>
  )
}

const SisselWork = (props) => {

  const { windowDimensions, sisselWorkData } = props;
  const { entries } = sisselWorkData;
  const { windowWidth, windowHeight } = windowDimensions;
  const [activeVideoPlayerIndex, setActiveVideoPlayerIndex] = useState(0);
  const container = useRef();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: .2 }}
      className="view--artist view--sissel"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <motion.div
        className="view--sissel__inner"
        ref={container}
      >
        {
          entries &&
          entries.map(
            (item, i) => (
              <VideoPlayer
                item={item}
                key={i}
                index={i}
                activeVideoPlayerIndex={activeVideoPlayerIndex}
                setActiveVideoPlayerIndex={setActiveVideoPlayerIndex}
                {...props}
                container={container}
              />
            )
          )
        }
      </motion.div>
    </motion.div>
  )
}

export default SisselWork;