import { useRef, useEffect, Suspense } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from 'three';
import { useGLTF, OrbitControls } from '@react-three/drei';
import { assetsPath } from "../../../utils/cockpit.js";

const GltfModel = (props) => {

  const { model, modelScale } = props;
  let modelPrimitive = useGLTF(model);
  const mixer = useRef();
  const modelMesh = useRef();

  useFrame(({ clock }) => {
    mixer.current && mixer.current.update(clock.getDelta() * 1.5)
  })

  useEffect(() => {
    if (modelMesh.current) {
      mixer.current = new THREE.AnimationMixer(modelPrimitive.scene);

      const { animations } = modelPrimitive;
      if (animations) {
        for (let animation of animations) {
          let action = mixer.current.clipAction(animation);
          action.play();
        }
      }
      modelMesh.current.scale.set(modelScale, modelScale, modelScale);
    }
  }, [modelScale, modelPrimitive]);

  // wrap modelPrimitive in primitive.
  return (
    <mesh ref={modelMesh}>
      <primitive object={modelPrimitive.scene} dispose={null} />
    </mesh>
  );
}

const Loader = () => {

  const loader = useRef();
  const currentTime = useRef(0);
  const scale = useRef(1);

  useFrame(() => {
    currentTime.current += 0.01;
    scale.current += Math.sin(currentTime.current) * 0.01;
    if (loader.current) {
      loader.current.scale.set(scale.current + 0.02, scale.current + 0.02, scale.current + 0.02);
    }
  });

  return (
    <mesh ref={loader}>
      <sphereBufferGeometry position={[0, 0, 0]} args={[0.5, 24, 24]} />
      <meshPhongMaterial color={0xffffff} smoothShading />
    </mesh>
  )
}

const MaryArtwork = (props) => {
  const { maryData, maryActiveWorkIndex } = props;
  const { models, modelScale } = maryData;
  const controls = useRef();

  const mesh = useRef();

  useEffect(() => {
    if (mesh.current) {
      mesh.current.scale.set(80, 80, 80);
    }
  }, []);

  return (
    <group>
      {
        models[maryActiveWorkIndex].value !== '' &&
        models[maryActiveWorkIndex].value.model !== '' &&
        <group ref={mesh}>
          <Suspense fallback={<Loader />}>
            <GltfModel model={assetsPath + models[maryActiveWorkIndex].value.model} modelScale={modelScale ? modelScale : 1} />
          </Suspense>
        </group>
      }
      <ambientLight color={0xffffff} intensity={0.4} />
      <OrbitControls ref={controls} />
    </group>
  );
}

export default MaryArtwork;