import { Fragment } from 'react';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const ArtworkIntroPage = (props) => {

  const { artistData, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const { title_slug, info, link_text, instructions } = artistData;
  const [textHeight, setTextHeight] = useState(0);
  const [textParentHeight, setTextParentHeight] = useState(0);
  const text = useRef();

  useEffect(() => {
    if (info && text.current) {
      setTextHeight(text.current.offsetHeight);
      setTextParentHeight(text.current.parentNode.offsetHeight);
      if (text.current.offsetHeight > text.current.parentNode.offsetHeight) {
        // setTextParentHeight(text.current.parentNode.offsetHeight);
        // setTextHeight(text.current.offsetHeight);
      }
    }
  }, [info, windowHeight]);

  return (
    <div className={`view view--artwork-intro-page view--default${textHeight > textParentHeight ? ' view--artwork-intro-page--scrolling-text' : ''}`}>
      <div className="container-fluid container-fluid--main view__inner view--layout--default__container-fluid">
        <div className="columns__wrapper view--layout--default__columns__wrapper">
          <div className={`col-xxs-12 col-sm-8 col-md-7 scroll-y view--layout--default__column view--layout--default__column--main view--layout--default__column--${textHeight > textParentHeight ? ' scrolling' : ''}`} height={windowHeight}>
            <div
              className={`view--layout--default__column__inner${textHeight > textParentHeight ? ' scrolling' : ''}`}
              style={{
                height: (windowWidth < 768 ? windowHeight - 140 : windowWidth < 1500 ? windowHeight - 40 : windowHeight - 60)  + 'px',
                marginTop: (windowWidth < 768 ? 90 : windowWidth > 1499 ? 60 : 40) + 'px'
              }}
            >
              <div className="view--layout--default__column__inner__text" ref={text}>
                <ReactMarkdown className="instructions">{info}</ReactMarkdown>
                {
                  windowWidth < 768 &&
                  <ReactMarkdown>{instructions}</ReactMarkdown>
                }
              </div>
            </div>
          </div>
          {
            windowWidth > 767 &&
            <div className="col-sm-4 col-md-5 view--layout--default__column scroll-y">
              <div className="view--layout--default__column__inner">
                <Link to="/info" className="link--info artwork-intro-page__link">Info</Link>
                <Link to={`/${title_slug}/view`} className="link--view-work button--default artwork-intro-page__link">{link_text}</Link>
                <ReactMarkdown className="instructions">{instructions}</ReactMarkdown>
              </div>
            </div>
          }
        </div>
      </div>
      {
        windowWidth < 768 &&
        <Fragment>
          <Link to="/info" className="link--info--mobile artwork-intro-page__link button--default">Info</Link>
          <Link to={`/${title_slug}/view`} className="link--view-work--mobile button--default artwork-intro-page__link">{link_text}</Link>
        </Fragment>
      }
    </div>
  )
}

export default ArtworkIntroPage;