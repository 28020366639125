import { useRef, useState, useEffect } from "react";
import * as THREE from 'three';
import { useFrame } from "@react-three/fiber";
import LuizaMesh from './MenuItems/LuizaMesh.js';
import ArchiveMesh from './MenuItems/ArchiveMesh.js';
import MaryMesh from './MenuItems/MaryMesh.js';
import EssayMesh from './MenuItems/EssayMesh.js';
import SisselMesh from './MenuItems/SisselMesh.js';

const ThreeMainMenu = (props) => {

  const { maryData, sisselData, luizaData, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const { pathname } = props.location;

  const [positions, setPositions] = useState([]);

  const menu = useRef();
  const luizaMesh = useRef();
  const archiveMesh = useRef();
  const sisselMesh = useRef();
  const flakesTexture = useRef();
  const maryMesh = useRef();
  const essayMesh = useRef();
  const particleLight = useRef();

  useEffect(() => {
    const positionsArray = [];
    let radius = 260;
    if (windowWidth < windowHeight) {
      radius = 200 * (windowWidth / windowHeight);
      if (menu.current) {
        menu.current.rotation.x = 0.3 * (windowHeight / windowWidth);
      }
    } else {
      if (menu.current) {
        menu.current.rotation.x = 0.3;
      }
    }
    const y = 0;
    for (let i = 0; i < 5; i++) {
      const position = new THREE.Vector3(0, 0, 0);
      const theta = i * 5 + Math.PI;
      position.setFromCylindricalCoords(radius, theta, y);
      positionsArray.push(position);
    }
    setPositions(positionsArray);

    if (archiveMesh.current) {
      archiveMesh.current.scale.set(0, 0, 0);
    }
    if (luizaMesh.current) {
      luizaMesh.current.scale.set(0, 0, 0);
    }
    if (maryMesh.current) {
      maryMesh.current.scale.set(0, 0, 0);
    }
    if (sisselMesh.current) {
      sisselMesh.current.scale.set(0, 0, 0);
    }
    if (essayMesh.current) {
      essayMesh.current.scale.set(0, 0, 0);
    }
  }, [windowWidth, windowHeight]);

  useFrame(() => {

    let lerpSpeed = 0.1;
    if (pathname !== '/menu') {
      // lerpSpeed = 0.005;
    }

    let menuScale = 1;
    let inactiveScale = 0.001;

    if (windowWidth < windowHeight) {
      menuScale = (windowWidth / windowHeight) * 0.9;
    }

    let activeScale = menuScale * 0.4;

    if (pathname === '/menu') {
      if (menu.current) {
        if (menu.current.rotation.y >= Math.PI * 2) {
          menu.current.rotation.y = 0;
        } else {
          menu.current.rotation.y += 0.001;
        }
      }
    } else {
      if (menu.current) {
        let rotationY = 0;
        if (pathname.indexOf('archive') > -1) {
          rotationY = (menu.current.rotation.y += (((Math.PI * 2) * 0.6) - menu.current.rotation.y) * 0.01);
        } if (pathname.indexOf('essay') > -1) {
          rotationY = (menu.current.rotation.y += (((Math.PI * 2) * 0.5) - menu.current.rotation.y) * 0.01);
        } else if (pathname === `/${luizaData.title_slug}/intro`) {
          rotationY = (menu.current.rotation.y += (((Math.PI * 2) * 0.9) - menu.current.rotation.y) * 0.01);
        } else if (pathname === `/${sisselData.title_slug}/intro`) {
          rotationY = (menu.current.rotation.y += (((Math.PI * 2) * 0.2) - menu.current.rotation.y) * 0.01);
        } else if (pathname === `/${maryData.title_slug}/intro`) {
          rotationY = (menu.current.rotation.y += (((Math.PI * 2) * 0.3) - menu.current.rotation.y) * 0.01);
        }
        menu.current.rotation.y = rotationY;
      }
    }

    if (luizaMesh.current) {
      //rotate
      luizaMesh.current.rotation.y -= 0.01;

      //lerp position
      let scale = (luizaMesh.current.scale.y += ((pathname === '/menu' ? menuScale : pathname === `/${luizaData.title_slug}/intro` ? activeScale : inactiveScale) - luizaMesh.current.scale.y) * lerpSpeed);
      luizaMesh.current.scale.set(scale, scale, scale);
    }
    if (archiveMesh.current) {
      //rotate
      archiveMesh.current.rotation.y -= 0.01;

      //lerp position
      let scale = (archiveMesh.current.scale.y += ((pathname === '/menu' ? menuScale : pathname === '/archive' ? activeScale : inactiveScale) - archiveMesh.current.scale.y) * lerpSpeed);
      archiveMesh.current.scale.set(scale, scale, scale);
    }
    if (essayMesh.current) {
      //rotate
      essayMesh.current.rotation.y -= 0.01;

      //scale
      let scale = (essayMesh.current.scale.y += ((pathname === '/menu' ? menuScale : pathname === '/essay' ? activeScale : inactiveScale) - essayMesh.current.scale.y) * lerpSpeed);
      essayMesh.current.scale.set(scale, scale, scale);
    }
    if (sisselMesh.current) {
      //rotate
      sisselMesh.current.rotation.y -= 0.01;

      //scale
      let scale = (sisselMesh.current.scale.y += ((pathname === '/menu' ? menuScale : pathname === `/${sisselData.title_slug}/intro` ? activeScale : inactiveScale) - sisselMesh.current.scale.y) * lerpSpeed);
      sisselMesh.current.scale.set(scale, scale, scale);
    }
    if (maryMesh.current) {
      //rotate
      maryMesh.current.rotation.y -= 0.01;

      //scale

      //lerp position
      let scale = (maryMesh.current.scale.y += ((pathname === '/menu' ? menuScale : pathname === `/${maryData.title_slug}/intro` ? activeScale : inactiveScale) - maryMesh.current.scale.y) * lerpSpeed);
      maryMesh.current.scale.set(scale, scale, scale);
    }
    if (flakesTexture.current) {
      flakesTexture.current.needsUpdate = true;
    }
    if (particleLight.current) {

      const timer = Date.now() * 0.00025;

      particleLight.current.position.x = Math.sin( timer * 7 ) * 300;
      particleLight.current.position.y = Math.cos( timer * 5 ) * 400;
      particleLight.current.position.z = Math.cos(timer * 3) * 300;
    }
  });

  return (
    <group ref={menu} position={[0, 0, -1250]} rotation={[0.2, 0, 0]}>
      <ambientLight color={'white'} intensity={0.3} />
      <pointLight color={'white'} intensity={0.6} ref={particleLight} />
      <pointLight color={'white'} intensity={0.6} />
      <LuizaMesh luizaMesh={luizaMesh} {...props} position={positions[0] ? positions[0] : [0, 0, 0]} />
      <ArchiveMesh archiveMesh={archiveMesh} {...props} position={positions[1] ? positions[1] : [0, 0, 0]} />
      <SisselMesh sisselMesh={sisselMesh} flakesTexture={flakesTexture} {...props} position={positions[2] ? positions[2] : [0, 0, 0]} />
      <MaryMesh maryMesh={maryMesh} {...props} position={positions[3] ? positions[3] : [0, 0, 0]} />
      <EssayMesh essayMesh={essayMesh} {...props} position={positions[4] ? positions[4] : [0, 0, 0]} />
    </group>
  )
}

export default ThreeMainMenu;