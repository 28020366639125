import ArtworkIntroPage from '../components/ArtworkIntroPage.js';

const Sissel = (props) => {

  const { sisselData } = props;

  return (
    <ArtworkIntroPage artistData={sisselData} {...props} />
  )
}

export default Sissel;