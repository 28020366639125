import { useEffect, useRef } from 'react';

const Cursor = (props) => {

  const { cursorIsOnLink } = props;
  const cursor = useRef();

  useEffect(() => {

    const handleMouseMove = (e) => {
      cursor.current.style.left = e.clientX + 20 + 'px';
      cursor.current.style.top = e.clientY + 20 + 'px';
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, []);

  return (
    <div
      ref={cursor}
      className={`cursor${cursorIsOnLink === true ? ' active' : ''}`}
    >T0x1c1ty$ R3@ch</div>
  )
}

export default Cursor;