import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const HeaderContentOnArchive = (props) => {
  const { firstContentColClassName } = props;
  return (
    <div className={firstContentColClassName}>Archive</div>
  )
}

const HeaderContentOnEssay = (props) => {
  const { firstContentColClassName } = props;
  return (
    <div className={firstContentColClassName}>Essay</div>
  )
}

const HeaderContentOnArtist = (props) => {
  const { firstContentColClassName, secondContentColClassName, artworkData } = props;
  return (
    <Fragment>
      <div className={firstContentColClassName}>{artworkData.artist_name}</div>
      <div className={secondContentColClassName}>{artworkData.title}, {artworkData.year}</div>
    </Fragment>
  )
}

const HeaderContentOnMenu = (props) => {
  const { hoveredMenuItemData, firstContentColClassName, secondContentColClassName } = props;
  return (
    <Fragment>
      {
        hoveredMenuItemData.artist &&
        <div className={firstContentColClassName}>{hoveredMenuItemData.artist}</div>
      }
      {
        hoveredMenuItemData.title &&
        <div className={secondContentColClassName}>{hoveredMenuItemData.title}</div>
      }
    </Fragment>
  )
}

const Header = (props) => {

  const { pathname, maryData, sisselData, luizaData } = props;
  const firstContentColClassName = "col-xxs-5 col-xs-3 col-sm-md-2 border-left header__col";
  const secondContentColClassName = "col-xxs-5 col-xs-6 border-left header__col";

  return (
    <header className="header container-fluid">
      <div className="columns__wrapper">
        <div className="col-xxs-2 col-xs-1 header__col">
          <Link to="/menu" className="header__link--home"></Link>
        </div>
        {
          pathname === '/menu' &&
          <HeaderContentOnMenu
            {...props}
            firstContentColClassName={firstContentColClassName}
            secondContentColClassName={secondContentColClassName}
          />
        }
        {
          pathname === '/archive' &&
          <HeaderContentOnArchive
            {...props}
            firstContentColClassName={firstContentColClassName}
          />
        }
        {
          pathname === '/essay' &&
          <HeaderContentOnEssay
            {...props}
            firstContentColClassName={firstContentColClassName}
          />
        }
        {
          pathname.indexOf(sisselData.title_slug) > -1 &&
          <HeaderContentOnArtist
            {...props}
            firstContentColClassName={firstContentColClassName}
            secondContentColClassName={secondContentColClassName}
            artworkData={sisselData}
          />
        }
        {
          pathname.indexOf(luizaData.title_slug) > -1 &&
          <HeaderContentOnArtist
            {...props}
            firstContentColClassName={firstContentColClassName}
            secondContentColClassName={secondContentColClassName}
            artworkData={luizaData}
          />
        }
        {
          pathname.indexOf(maryData.title_slug) > -1 &&
          <HeaderContentOnArtist
            {...props}
            firstContentColClassName={firstContentColClassName}
            secondContentColClassName={secondContentColClassName}
            artworkData={maryData}
          />
        }
      </div>
    </header>
  )
}

export default Header;