import { useMemo } from "react";
import * as THREE from "three";
import envPath from '../../../../img/texture/envMap.jpg';
import normalPath from '../../../../img/texture/perlin_noise.jpg';
import clearCoatNormalPath from '../../../../img/texture/perlin_noise.jpg';

const MaryMesh = (props) => {

  const { maryMesh, setHoveredMenuItemData, setCursorIsOnLink, pathname, maryData, position, navigate } = props;

  const normal = useMemo(() => new THREE.TextureLoader().load(normalPath), []);
  const clearCoatNormal = useMemo(() => new THREE.TextureLoader().load(clearCoatNormalPath), []);
  const envMap = useMemo(() => new THREE.TextureLoader().load(envPath), []);

  return (
    <mesh
      ref={maryMesh}
      position={position}
      onClick={() => {
        if (pathname === '/menu') {
          navigate(`/${maryData.title_slug}/intro`);
        } else {
          navigate('/menu');
        }
        setCursorIsOnLink(false);
      }}
      onPointerOver={() => {
        setHoveredMenuItemData({
          artist: maryData.artist_name,
          title: `${maryData.title}, ${maryData.year}`
        });
        setCursorIsOnLink(true);
      }}
      onPointerOut={() => {
        setHoveredMenuItemData({});
        setCursorIsOnLink(false);
      }}
    >
      <sphereBufferGeometry args={[ 80, 64, 32 ]} />
      <meshPhysicalMaterial
        attach="material"
        side={THREE.DoubleSide}
        clearcoat={1.0}
        metalness={1.0}
        color="#ff0000"
        normalScale={new THREE.Vector2(20, 20)}
        clearcoatNormalScale={new THREE.Vector2(2.0, -2.0)}
      >
        <primitive
          attach="normalMap"
          object={normal}
        />
        <primitive
          attach="clearcoatNormalMap"
          object={clearCoatNormal}
        />
        <primitive
          attach="envMap"
          object={envMap}
        />
      </meshPhysicalMaterial>
    </mesh>
  )
}

export default MaryMesh;